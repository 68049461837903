<template>
  <div class="main">
    <div class="all-chart">
      <div class="all-people">
        <div class="number">
          {{ areaRatinAge.olderInfo.profile.olderCount }}
        </div>
        <div class="desc">老龄人口数量</div>
      </div>
      <div class="charts">
        <div class="chart1">
          <div id="oldrate"></div>
          <div class="desc">
            老龄化 {{ areaRatinAge.olderInfo.profile.olderRatio + '%' }}
          </div>
        </div>
        <div class="chart2">
          <div id="oldGender"></div>
          <div class="desc">
            <template v-if="areaRatinAge.olderInfo.profile.sexRatio != 0">
              男:{{ areaRatinAge.olderInfo.profile.sexRatio + '%' }} 女:{{
                (100 - areaRatinAge.olderInfo.profile.sexRatio).toFixed(2) + '%'
              }}
            </template>
            <template v-else> 男:0% 女:0% </template>
          </div>
        </div>
        <div class="chart3">
          <div id="urbanization"></div>
          <div class="desc">
            <template v-if="areaRatinAge.olderInfo.profile.townRatio != 0">
              乡:{{ areaRatinAge.olderInfo.profile.townRatio + '%' }} 城:{{
                (100 - areaRatinAge.olderInfo.profile.townRatio).toFixed(2) +
                '%'
              }}
            </template>
            <template v-else> 乡:0% 城:0% </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { getStatistics } from '@/api/homePage/left.js'
export default {
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getStatistics()
  },
  data() {
    return {
      areaRatinAge: {
        olderInfo: {
          profile: {
            olderCount: 0, // 老龄人口数量
            olderRatio: 0, // 老龄化
            sexRatio: 0, // 男
            townRatio: 0, // 乡
          },
        },
      },
    }
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.areaRatinAge = res.data
          this.initChartOfOldRate() // 老龄化
          this.initChartOfOldGender() // 男女比例
          this.initChartOfUrbanization() // 乡城比例
        }
      })
    },
    initChartOfOldRate() {
      this.$nextTick(() => {
        var getvalue = this.areaRatinAge.olderInfo.profile.olderRatio
        var reslut = 100 - this.areaRatinAge.olderInfo.profile.olderRatio
        var oldRate = echarts.init(document.getElementById('oldrate'))
        let option = {
          color: ['#FF6F0E', '#1F2A64'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '老龄化',
                color: '#69B1FF',
                fontWeight: 'bold',
              },
              data: [
                { value: getvalue, name: '老龄化' },
                { value: reslut, name: '' },
              ],
            },
          ],
        }
        oldRate.setOption(option)
        window.addEventListener('resize', function () {
          oldRate.resize()
        })
      })
    },
    initChartOfOldGender() {
      this.$nextTick(() => {
        var male
        var female
        if (this.areaRatinAge.olderInfo.profile.sexRatio == '0') {
          male = 0
          female = 0
        } else {
          male = this.areaRatinAge.olderInfo.profile.sexRatio
          female = 100 - this.areaRatinAge.olderInfo.profile.sexRatio
        }
        var oldGender = echarts.init(document.getElementById('oldGender'))
        let option = {
          color: ['#FF6351', '#2DFF99'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '性别',
                color: '#69B1FF',
                fontWeight: 'bold',
              },
              data: [
                { value: male, name: '男' },
                { value: female, name: '女' },
              ],
            },
          ],
        }
        oldGender.setOption(option)
        window.addEventListener('resize', function () {
          oldGender.resize()
        })
      })
    },
    initChartOfUrbanization() {
      this.$nextTick(() => {
        var agriculture
        var citizen
        if (this.areaRatinAge.olderInfo.profile.townRatio == '0') {
          agriculture = 1
          citizen = 1
        } else {
          agriculture = this.areaRatinAge.olderInfo.profile.townRatio
          citizen = 100 - this.areaRatinAge.olderInfo.profile.townRatio
        }
        var urbanization = echarts.init(document.getElementById('urbanization'))
        let option = {
          color: ['#9900FF ', '#43E6FF'],
          tooltip: {
            show: false,
          },
          series: [
            {
              hoverAnimation: false,
              type: 'pie',
              radius: ['55%', '90%'],
              label: {
                show: true,
                position: 'center',
                formatter: '城镇',
                color: '#69B1FF',
                fontWeight: 'bold',
              },
              data: [
                { value: agriculture, name: '乡' },
                { value: citizen, name: '镇' },
              ],
            },
          ],
        }
        urbanization.setOption(option)
        window.addEventListener('resize', function () {
          urbanization.resize()
        })
      })
    },
  },
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 9.45rem;
  background: #051137;
  box-shadow: inset 0px 0px 0.9rem 0px rgba(58, 95, 255, 0.5);
  border-radius: 0.08rem;
  border: 0.04rem solid rgba(80, 142, 255, 0.22);
  background: url('~@/assets/images/homePage_images/populationOverview.png') 0 0
    no-repeat;
  background-size: 100% 100%;
}

.all-chart {
  height: 6.8rem;
  margin: 1.9rem 0.3rem 0.42rem;
  display: flex;
}
.all-people {
  height: 100%;
  width: 6.33rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.083rem;
  border: 0.042rem solid #213060;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.number {
  width: fit-content;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.desc {
  width: fit-content;
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  margin: 0 auto;
  margin-bottom: 0.83rem;
  text-align: center;
}
.charts {
  width: 17.8rem;
  height: 100%;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.083rem;
  border: 0.042rem solid #213060;
  margin-left: 0.42rem;
  display: flex;
}
.chart1,
.chart2,
.chart3 {
  width: 33%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
#oldrate,
#oldGender,
#urbanization {
  width: 100%;
  height: 80%;
}
</style>
